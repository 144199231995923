import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import video from  '../../videos/profilevid.mp4'
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, resume } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="about">
      <Container>
        <Title title="About Me" padding-bottom= "0rem" />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={1000} delay={600} distance="30px">
              <div className="about-wrapper__image">
              <video src={video} width="450" height="400"  loop  autoplay="true" muted="true" ></video>
              
              </div>
            </Fade>
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="about-wrapper__info">
                <p className="about-wrapper__info-text" >
                  {paragraphOne ||
                    'I am hardworking, energetic, friendly and a team player. I work well under pressure and I am self motivated. I can multitask, in my previous job as an assistant manager I needed to be able to cover any role as well as do my own job at any given time.I am able manage myself when working remotely as evidenced by my time working remotely for Amazon customer service.These experiences also showed that I am good at multitasking and time management. '}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphTwo ||
                    'I want to make a start in the IT industry which has been my dream since highschool, I currently have a scholarship through Google and Andela and I aim to complete in order to gain my Google certification.I want to grow in the IT industry so I can Widen my scope of IT skills.'}
                </p>
                <p className="about-wrapper__info-text">
                  {paragraphThree || 'continue below to find out more about me and my projects.'}
                </p>
                {resume && (
                  <span className="d-flex mt-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={resume}
                    >
                      Resume
                    </a>
                  </span>
                )}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
